.container {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    background-color: #ffe9d8ee;
    background-clip: content-box;
    width: 100%;
    height: 60vh;
    z-index: 0;
    margin-top: 90vh;
    padding-top: 20vh;
    padding-bottom: 20vh;
}

.content {
    margin: 30px;
}

b {
    font-weight: 300;
}