.layout {
  width: 100vw;
  background-color: white;
  overflow-anchor: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-anchor: none;
}

.logo {
  position: fixed;
  width: 14vmax;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  mix-blend-mode: color-burn;
  filter: invert(20%) brightness(35%) sepia(50%) saturate(700%) hue-rotate(35deg);
  pointer-events: none;
  user-select: none;
}

.header {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  height: 10vh;
  width: calc(100% - 60px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: #770000;
  padding: 30px;
}

.companyTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
}

.companyName {
  font-size: calc(40px + 2vmin);
  font-weight: 300;
}

.companySubtitle {
  margin-top: -10px;
  font-size: calc(15px + 1vmin);
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: calc(22px + 1vmin);
}