html {
  background-color: white;
  scroll-behavior: smooth;
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  overflow-anchor: none;
}

body {
  scroll-behavior: smooth;
  height: 100vh;
  width: 100dvw;
  margin: 0;
  font-family: "Albert Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-size: calc(16px + 1vmin);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #290000;
  background-color: white;
  overflow-anchor: none;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  display: inline-block;
  position: relative;
}

a:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #a64a4a;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

a:link {
  color: inherit;
}

a:visited {
  color: inherit;
}

a:hover {
  color: inherit;
}

a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

a:active {
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
